export const appKeepalive = true

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,viewport-fit=cover"},{"charset":"utf-8"},{"property":"og:title","content":"reunity.app"},{"property":"og:description","content":"Reunity enables travelers to reunite by sharing their travel plans with the friends they made along the way."},{"property":"og:type","content":"website"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/icon.svg"},{"rel":"manifest","href":"/manifest.webmanifest"}],"style":[],"script":[],"noscript":[],"viewport":"width=device-width,initial-scale=1,viewport-fit=cover","bodyAttrs":{"class":"overflow-x-hidden"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'