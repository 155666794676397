import { createTRPCNuxtClient } from 'trpc-nuxt/client'
import type { AppRouter } from '@/server/trpc/routers'
import { httpBatchLink } from '@trpc/client'

function getToken(): string {
  const { public: publicConfig } = useRuntimeConfig()
  const token = localStorage.getItem('sb-icilhzpnwelyuykcnspw-auth-token')
  if (token) {
    const json = JSON.parse(token)
    return json.access_token
  }

  return publicConfig.supabase.key // use anon key
}
export default defineNuxtPlugin(() => {
  /**
   * createTRPCNuxtClient adds a `useQuery` composable
   * built on top of `useAsyncData`.
   */
  const client = createTRPCNuxtClient<AppRouter>({
    links: [
      httpBatchLink({
        url: '/api/trpc',
        headers() {
          return {
            Authorization: getToken(),
          }
        },
      }),
    ],
  })

  return {
    provide: {
      client,
    },
  }
})
