import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as avatarXWSnM7TWJVMeta } from "/vercel/path0/pages/account/avatar.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as authRedirectLKtg2jkKG6Meta } from "/vercel/path0/pages/authRedirect.vue?macro=true";
import { default as debugjeBOSB0Qf9Meta } from "/vercel/path0/pages/debug.vue?macro=true";
import { default as _91id_93lsGVXPyQbxMeta } from "/vercel/path0/pages/event/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93OdyDspcg56Meta } from "/vercel/path0/pages/invite/accept/[id].vue?macro=true";
import { default as indexFNhsUZOYfgMeta } from "/vercel/path0/pages/invite/index.vue?macro=true";
import { default as _91slug_93WKA23LX6A3Meta } from "/vercel/path0/pages/location/[slug].vue?macro=true";
import { default as my_45friendsTQRMAXLOhcMeta } from "/vercel/path0/pages/my-friends.vue?macro=true";
import { default as my_45tripsWqEvjslBVWMeta } from "/vercel/path0/pages/my-trips.vue?macro=true";
import { default as avatarPVqxD9zMdEMeta } from "/vercel/path0/pages/onboarding/avatar.vue?macro=true";
import { default as nameQB6JArtP4nMeta } from "/vercel/path0/pages/onboarding/name.vue?macro=true";
import { default as _91username_93haiyPbT1feMeta } from "/vercel/path0/pages/profile/[username].vue?macro=true";
import { default as recommendations2CM5tsetslMeta } from "/vercel/path0/pages/recommendations.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as sign_45innR2QzqahJfMeta } from "/vercel/path0/pages/sign-in.vue?macro=true";
import { default as sign_45upHNIilXjClyMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
import { default as _91id_93OEqj8yv1U9Meta } from "/vercel/path0/pages/tribe/[id].vue?macro=true";
import { default as createrL11dzwx65Meta } from "/vercel/path0/pages/tribe/create.vue?macro=true";
import { default as _91id_93mdoy6NZWUHMeta } from "/vercel/path0/pages/tribe/join/[id].vue?macro=true";
import { default as listxEp7PUIziRMeta } from "/vercel/path0/pages/tribe/list.vue?macro=true";
import { default as _91id_93OlHSgDKIWLMeta } from "/vercel/path0/pages/trip/[id].vue?macro=true";
import { default as addQPfCvKVs1wMeta } from "/vercel/path0/pages/trip/add.vue?macro=true";
import { default as upcoming_45tripsYpmBEGaF3SMeta } from "/vercel/path0/pages/upcoming-trips.vue?macro=true";
import { default as world_45mapQGWe7nkGC3Meta } from "/vercel/path0/pages/world-map.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: avatarXWSnM7TWJVMeta?.name ?? "account-avatar",
    path: avatarXWSnM7TWJVMeta?.path ?? "/account/avatar",
    meta: avatarXWSnM7TWJVMeta || {},
    alias: avatarXWSnM7TWJVMeta?.alias || [],
    redirect: avatarXWSnM7TWJVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/avatar.vue").then(m => m.default || m)
  },
  {
    name: indexqOnLrLMUjLMeta?.name ?? "account",
    path: indexqOnLrLMUjLMeta?.path ?? "/account",
    meta: indexqOnLrLMUjLMeta || {},
    alias: indexqOnLrLMUjLMeta?.alias || [],
    redirect: indexqOnLrLMUjLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: authRedirectLKtg2jkKG6Meta?.name ?? "authRedirect",
    path: authRedirectLKtg2jkKG6Meta?.path ?? "/authRedirect",
    meta: authRedirectLKtg2jkKG6Meta || {},
    alias: authRedirectLKtg2jkKG6Meta?.alias || [],
    redirect: authRedirectLKtg2jkKG6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/authRedirect.vue").then(m => m.default || m)
  },
  {
    name: debugjeBOSB0Qf9Meta?.name ?? "debug",
    path: debugjeBOSB0Qf9Meta?.path ?? "/debug",
    meta: debugjeBOSB0Qf9Meta || {},
    alias: debugjeBOSB0Qf9Meta?.alias || [],
    redirect: debugjeBOSB0Qf9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/debug.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lsGVXPyQbxMeta?.name ?? "event-id",
    path: _91id_93lsGVXPyQbxMeta?.path ?? "/event/:id()",
    meta: _91id_93lsGVXPyQbxMeta || {},
    alias: _91id_93lsGVXPyQbxMeta?.alias || [],
    redirect: _91id_93lsGVXPyQbxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/event/[id].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OdyDspcg56Meta?.name ?? "invite-accept-id",
    path: _91id_93OdyDspcg56Meta?.path ?? "/invite/accept/:id()",
    meta: _91id_93OdyDspcg56Meta || {},
    alias: _91id_93OdyDspcg56Meta?.alias || [],
    redirect: _91id_93OdyDspcg56Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/invite/accept/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFNhsUZOYfgMeta?.name ?? "invite",
    path: indexFNhsUZOYfgMeta?.path ?? "/invite",
    meta: indexFNhsUZOYfgMeta || {},
    alias: indexFNhsUZOYfgMeta?.alias || [],
    redirect: indexFNhsUZOYfgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WKA23LX6A3Meta?.name ?? "location-slug",
    path: _91slug_93WKA23LX6A3Meta?.path ?? "/location/:slug()",
    meta: _91slug_93WKA23LX6A3Meta || {},
    alias: _91slug_93WKA23LX6A3Meta?.alias || [],
    redirect: _91slug_93WKA23LX6A3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/location/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45friendsTQRMAXLOhcMeta?.name ?? "my-friends",
    path: my_45friendsTQRMAXLOhcMeta?.path ?? "/my-friends",
    meta: my_45friendsTQRMAXLOhcMeta || {},
    alias: my_45friendsTQRMAXLOhcMeta?.alias || [],
    redirect: my_45friendsTQRMAXLOhcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-friends.vue").then(m => m.default || m)
  },
  {
    name: my_45tripsWqEvjslBVWMeta?.name ?? "my-trips",
    path: my_45tripsWqEvjslBVWMeta?.path ?? "/my-trips",
    meta: my_45tripsWqEvjslBVWMeta || {},
    alias: my_45tripsWqEvjslBVWMeta?.alias || [],
    redirect: my_45tripsWqEvjslBVWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my-trips.vue").then(m => m.default || m)
  },
  {
    name: avatarPVqxD9zMdEMeta?.name ?? "onboarding-avatar",
    path: avatarPVqxD9zMdEMeta?.path ?? "/onboarding/avatar",
    meta: avatarPVqxD9zMdEMeta || {},
    alias: avatarPVqxD9zMdEMeta?.alias || [],
    redirect: avatarPVqxD9zMdEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/onboarding/avatar.vue").then(m => m.default || m)
  },
  {
    name: nameQB6JArtP4nMeta?.name ?? "onboarding-name",
    path: nameQB6JArtP4nMeta?.path ?? "/onboarding/name",
    meta: nameQB6JArtP4nMeta || {},
    alias: nameQB6JArtP4nMeta?.alias || [],
    redirect: nameQB6JArtP4nMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/onboarding/name.vue").then(m => m.default || m)
  },
  {
    name: _91username_93haiyPbT1feMeta?.name ?? "profile-username",
    path: _91username_93haiyPbT1feMeta?.path ?? "/profile/:username()",
    meta: _91username_93haiyPbT1feMeta || {},
    alias: _91username_93haiyPbT1feMeta?.alias || [],
    redirect: _91username_93haiyPbT1feMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: recommendations2CM5tsetslMeta?.name ?? "recommendations",
    path: recommendations2CM5tsetslMeta?.path ?? "/recommendations",
    meta: recommendations2CM5tsetslMeta || {},
    alias: recommendations2CM5tsetslMeta?.alias || [],
    redirect: recommendations2CM5tsetslMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/recommendations.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? "settings",
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: sign_45innR2QzqahJfMeta?.name ?? "sign-in",
    path: sign_45innR2QzqahJfMeta?.path ?? "/sign-in",
    meta: sign_45innR2QzqahJfMeta || {},
    alias: sign_45innR2QzqahJfMeta?.alias || [],
    redirect: sign_45innR2QzqahJfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upHNIilXjClyMeta?.name ?? "sign-up",
    path: sign_45upHNIilXjClyMeta?.path ?? "/sign-up",
    meta: sign_45upHNIilXjClyMeta || {},
    alias: sign_45upHNIilXjClyMeta?.alias || [],
    redirect: sign_45upHNIilXjClyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OEqj8yv1U9Meta?.name ?? "tribe-id",
    path: _91id_93OEqj8yv1U9Meta?.path ?? "/tribe/:id()",
    meta: _91id_93OEqj8yv1U9Meta || {},
    alias: _91id_93OEqj8yv1U9Meta?.alias || [],
    redirect: _91id_93OEqj8yv1U9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tribe/[id].vue").then(m => m.default || m)
  },
  {
    name: createrL11dzwx65Meta?.name ?? "tribe-create",
    path: createrL11dzwx65Meta?.path ?? "/tribe/create",
    meta: createrL11dzwx65Meta || {},
    alias: createrL11dzwx65Meta?.alias || [],
    redirect: createrL11dzwx65Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tribe/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mdoy6NZWUHMeta?.name ?? "tribe-join-id",
    path: _91id_93mdoy6NZWUHMeta?.path ?? "/tribe/join/:id()",
    meta: _91id_93mdoy6NZWUHMeta || {},
    alias: _91id_93mdoy6NZWUHMeta?.alias || [],
    redirect: _91id_93mdoy6NZWUHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tribe/join/[id].vue").then(m => m.default || m)
  },
  {
    name: listxEp7PUIziRMeta?.name ?? "tribe-list",
    path: listxEp7PUIziRMeta?.path ?? "/tribe/list",
    meta: listxEp7PUIziRMeta || {},
    alias: listxEp7PUIziRMeta?.alias || [],
    redirect: listxEp7PUIziRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tribe/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OlHSgDKIWLMeta?.name ?? "trip-id",
    path: _91id_93OlHSgDKIWLMeta?.path ?? "/trip/:id()",
    meta: _91id_93OlHSgDKIWLMeta || {},
    alias: _91id_93OlHSgDKIWLMeta?.alias || [],
    redirect: _91id_93OlHSgDKIWLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/trip/[id].vue").then(m => m.default || m)
  },
  {
    name: addQPfCvKVs1wMeta?.name ?? "trip-add",
    path: addQPfCvKVs1wMeta?.path ?? "/trip/add",
    meta: addQPfCvKVs1wMeta || {},
    alias: addQPfCvKVs1wMeta?.alias || [],
    redirect: addQPfCvKVs1wMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/trip/add.vue").then(m => m.default || m)
  },
  {
    name: upcoming_45tripsYpmBEGaF3SMeta?.name ?? "upcoming-trips",
    path: upcoming_45tripsYpmBEGaF3SMeta?.path ?? "/upcoming-trips",
    meta: upcoming_45tripsYpmBEGaF3SMeta || {},
    alias: upcoming_45tripsYpmBEGaF3SMeta?.alias || [],
    redirect: upcoming_45tripsYpmBEGaF3SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/upcoming-trips.vue").then(m => m.default || m)
  },
  {
    name: world_45mapQGWe7nkGC3Meta?.name ?? "world-map",
    path: world_45mapQGWe7nkGC3Meta?.path ?? "/world-map",
    meta: world_45mapQGWe7nkGC3Meta || {},
    alias: world_45mapQGWe7nkGC3Meta?.alias || [],
    redirect: world_45mapQGWe7nkGC3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/world-map.vue").then(m => m.default || m)
  }
]