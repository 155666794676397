import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import colors from '~/utils/colors'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'banana',
      themes: {
        banana: {
          dark: false,
          colors: {
            primary: colors.yellow.darken2,
            secondary: colors.grey.darken1,
            accent: colors.amber.darken3,
            info: colors.teal.lighten1,
            warning: colors.deepOrange.darken1,
            error: colors.deepOrange.accent4,
            success: '#3c984c',
            background: colors.shades.white,
          },
        },
        bananaDark: {
          dark: true,
          colors: {
            primary: colors.yellow.darken2,
            secondary: colors.grey.darken1,
            accent: colors.amber.darken3,
            info: colors.teal.lighten1,
            warning: colors.deepOrange.darken1,
            error: colors.deepOrange.accent4,
            success: '#3c984c',
            background: colors.shades.white,
          },
        },
      },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})
