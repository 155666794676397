import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(() => {
  const store = useNotificationStore()

  const toaster = {
    show({ title = '', color = '' }) {
      store.toasts.set(title, { title, color })
    },
    hide(title: string) {
      store.$patch((state) => {
        store.toasts.delete(title)
        state.hasChanged = true
      })
    },
  }

  return {
    provide: {
      toaster,
    },
  }
})
