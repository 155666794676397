import { defineNuxtPlugin } from '#app'
import * as pkg from '~/package.json'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

export default defineNuxtPlugin((nuxtApp) => {
  const release = `banana-app@${pkg.version}`
  const environment = nuxtApp.$config.public.ENV
  const isDev = nuxtApp.$config.public.isDev

  if (isDev) {
    return
  }

  Sentry.init({
    dsn: 'https://fdf3986a64c4993346c5f8980ac6dfd8@o4505668791500800.ingest.sentry.io/4505669043355648',
    release,
    environment,
    integrations: [new Integrations.BrowserTracing()],
    sampleRate: 1,
    tracesSampleRate: 1,
  })
})
