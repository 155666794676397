<template>
  <v-app dark>
    <v-container fluid>
      <v-card class="mx-auto my-12">
        <v-card-title class="title">
          {{ title }}
        </v-card-title>
        <v-card-text class="text">
          <b>{{ errorDetails }}</b
          ><br />
          {{ stack }}
        </v-card-text>
        <v-card-actions>
          <v-btn text to="/"> Return Home </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    return {
      title: this.title,
    }
  },
  computed: {
    is404() {
      return this.error.statusCode === 404
    },
    title() {
      return this.is404
        ? '404 - Page not found'
        : 'An unexpected error occurred!'
    },
    errorDetails() {
      return this.is404
        ? 'The route was not found. Either it has changed or, if you use a browser, you entered an unknown URL.'
        : this.error.message
    },
    stack() {
      return this.error.stack
    },
  },
}
</script>

<style scoped>
.title {
  background-color: #eb5406;
}

.v-card__title + .v-card__text {
  padding-top: 2rem;
}
</style>
