import { acceptHMRUpdate, defineStore } from 'pinia'

export type Toast = {
  title: string
  color: string
}
interface NotificationState {
  toasts: Map<string, Toast>
}

export const useNotificationStore = defineStore('notification', {
  state(): NotificationState {
    return {
      toasts: new Map<string, Toast>(),
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
